import { Sendform } from '../libs/sendform/sendform2';
import { Slider } from '../libs/slider';
import '../../node_modules/select2/dist/js/select2.min.js';
//JQuery Mask
import '../libs/maskedinput.min.js';

$( document ).ready(function() {
    let projectApp = new App();
    global.app = projectApp;
    projectApp.init();
});


class App{
    // Define global visible variable inside app 
    constructor(){}
    init(){

        //Fixed header on mobile
        $(window).scroll(function(){
            let header = $('.header'),
                scroll = $(window).scrollTop()
            if (scroll >= 60) header.addClass('__fixed')
            else header.removeClass('__fixed')
        })

        //Scroll
        let $links = $('.js_scroll-lnk');
        $links.each(function() {
            $(this).click(function(){
                $links.each(function() {
                    $(this).removeClass('__active')
                })
                $(this).addClass('__active')
                $('html, body').animate({
                    scrollTop: $( $(this).attr('href') ).offset().top
                }, 1000)
                return false
            })
        })

        //Send form
        $('.js_send-form').each(function(){
            new Sendform($(this)[0])
        })

        //Mobile menu
        $('body').on('click', '.js_menu-btn', function(e) {
            e.preventDefault()
            $(this).toggleClass('__active')
            if($(this).hasClass('__active')){
                $('.js_menu-cnt').addClass('__show-menu')
            } else{
                $('.js_menu-cnt').removeClass('__show-menu')
            }
        })

        //Slider about us
        new Slider('.js_carousel-about',
            '.js_carousel-about-prev',
            '.js_carousel-about-next',
            true, {
                center: true,
                items:1,
                loop: true,
                margin: 0,
                nav: false,
                autoHeight: false,
                autoplay: false,
                autoplayTimeout: 5000,
                autoplayHoverPause: true,
                dots: false,
                paginationNumbers: false,
                responsive:{
                    680:{
                        items: 2,
                        dots: false,
                        margin: 20,
                    }
                }
            })
    }
};
